<template lang="pug">
div
  van-nav-bar(title='', 
    right-arrow, 
    @click-right='onHome'
    fixed)
    template(#right='')
      van-icon(name='home-o', size='18')
  .detailpicbox
    img.img(v-lazy='item.url' v-for='(item,index) of detailpics', :key='index')
    van-goods-action
      van-button(type='info' block @click="show = true") 合作咨询
    van-action-sheet(v-model='show', title='合作咨询')
      van-row(type="flex" justify="center")
        p.linktitle 联系我们 请拨打合作咨询热线
      van-row(type="flex" justify="center")
        p.tel
          a(href='tel:189 1119 2268') 189 1119 2268
      van-row(type="flex" justify="center")
        span.subline
      van-row(type="flex")
        van-col( offset='1')
          p.tipstitle 或者填写您的联系方式，我们会主动与您联系:
      van-form(@submit='onSubmit' colon)
        van-field(v-model='info.user_name', label='姓名', placeholder='请填写您的姓名', clearable required :rules="[{ required: true, message: '请填写您的姓名' }]")
        van-field(v-model='info.tel', type='tel',  label='联系电话', placeholder='请填写您的联系电话',clearable, required :rules="[{validator, message: '请输入正确的手机号' }]")
        van-field(v-model='info.name', label='公司名称', placeholder='请填写您的公司名称')
        div(style='margin: 16px;')
          van-button(round='', block='', type='info', native-type='submit' :disabled='commitState')
            | 提交
</template>

<script>
import Vue from 'vue';
import { NavBar } from 'vant';

Vue.use(NavBar)
export default {
  components: {
  },
  data() {
    return {
      commitState: false,
      show: false,
      info: {
        user_name: '',
        tel: '',
        name: '',
        from: '云展厅-合作咨询'
      },
      detailpics: [
        {
          url: require('../assets/dpjia/1.jpg')
        },
        {
          url: require('../assets/dpjia/2.jpg')
        },
        {
          url: require('../assets/dpjia/3.jpg')
        },
        {
          url: require('../assets/dpjia/4.jpg')
        },
        {
          url: require('../assets/dpjia/5.jpg')
        },
        {
          url: require('../assets/dpjia/6.jpg')
        }
      ]
    }
  },
  computed:{
    routeInfo () {
      return this.$store.state
    }
  },
  methods:{
    onHome() {
      this.$router.push({name: 'globalHome'})
    },
    async onSubmit() {
      this.commitState = true
      try{
        await this.$api.PostConsulting(this.info)
        this.show = false
        this.$toast('提交成功,我们会尽快联系您')
      }catch(ex){
        this.$toast(ex)
      }
      this.commitState = false
    },
    // 验证手机号
     validator(val) {
      let reg = /^1[0-9]{10}$/
      if (val == '' || val.length <= 10 || !reg.test(val)) {
          return false
      }
      return true
    }
  },
}
</script>

<style scoped>
 .linktitle{
    margin: 10px 0;
    font-size: 12px;
    color: #666;
  }
  .tel{
    font-size: 20px;
    color: #333;
  }
  .tel a{
    color: #333;
  }
  .subline{
    display:inline-block;
    width: 50px;
    height: 2px;
    background-color: #5278E5;
  }
  .tipstitle{
    text-align: left;
    font-size: 12px;
    color: #666;
    margin-top: 15px;
  }
  .detailpicbox{
  width: 100%;
  }
  .detailpicbox img {
    width: 100%;
    vertical-align: middle;
  }
</style>